export const  CustomJobFields = (props) => {
    const {item,index,formErrors,handleChange,customAns} = props;
    const splitString = item.split("):");
    const labelTitle = (splitString[1]) ? splitString[1] : '';
    var fieldType = 'SL';
    var fieldOption = {};
    var fieldName = "cutomeField_"+index;
    if(splitString[0] && (splitString[0] != null) && (splitString[0] != undefined)){
        if(splitString[0].indexOf('DD') >= 0) {
          fieldType = 'DD';
          const splitOption = splitString[0].split("-");
          if(splitOption[1]){
              fieldOption = splitOption[1].split(",");
          }
        } else if(splitString[0].indexOf('ML')  >= 0) {
          fieldType = 'ML';
        }
    }

    return (
      <div className="mb-3 row" key={index}>
          <label className="col-sm-12 col-form-label job-subpara-text">{labelTitle} <span className="error">*</span></label>
          <div className="col-sm-12">
            {fieldType == "SL" &&
              <input 
              className={`form-control job-subpara-text ${
                  formErrors && formErrors[fieldName] ? "error" : ""
                }`}
              name={fieldName}
              value={(customAns[index]) ? customAns[index] : ''}
              onChange={handleChange}
              key={'field_'+index}
              />
            }

            {fieldType == "ML" &&
              <textarea 
              className={`form-control job-subpara-text ${
                  formErrors && formErrors.commuteBe ? "error" : ""
                }`}
              name={fieldName}
              onChange={handleChange}
              key={'field_'+index}
              />
            }

            {fieldType == "DD" &&
              <select 
              className={`form-control job-subpara-text ${
                formErrors && formErrors.backgroundCheck ? "error" : ""
              }`}
              name={fieldName}
              onChange={handleChange}
              key={'field_'+index}
              >
                <option value={''} >Select</option>
                {fieldOption && fieldOption.length > 0 &&
                  fieldOption.map((mapItem,mapIndex) => 
                  <option value={mapItem} key={'option_'+index+mapIndex} >{mapItem}</option>
                  )
                }
              </select>
            }
          
            {formErrors && formErrors[fieldName] ? 
              <label className="job-subpara-text error">{formErrors[fieldName]}</label>
            :
              ''
            }
          </div>
      </div>
    )
  }