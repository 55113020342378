import React, { useState, useEffect,useRef } from "react";
import { useParams,useNavigate, useLocation } from "react-router-dom";
import axios from "../../axios";
import { CustomJobFields } from "../../components/custom-job-fields";
import DocumentMeta from "react-document-meta";
import ReCAPTCHA from "react-google-recaptcha";

export const JobApplication = () => {
  const recaptcha = useRef();
  const [meta, setMeta] = useState({
    title: "MEDNA Job Application",
    description: "MEDNA Job Application",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "MEDNA Job Application",
      },
    },
  });
    const [formInputs, setFormInputs] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [jobFields, setJobFields] = useState({});
    const [customAns, setCustomAns] = useState({});
    const [actualData, setActualData] = useState('');
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [files, setFiles] = useState('');
    const [fileError, setFileError] = useState('');
    const [createdRecordID, setCreatedRecordID] = useState('');
    const [filesName, setFilesName] = useState('');
    const [buttonLoading,setButtonLoading] = useState(false);

    const regExp = RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);

    const {jobId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const jobTitle = (location?.state?.jobTitle) ? location.state.jobTitle : '';
    if(!jobTitle) {
        navigate("/company/job-openings/"+jobId);
    }
    
    useEffect(() => {
      axios
      .post("v1/records-with-count", {
        tableId: process.env.REACT_APP_JO_TABLE_ID,
        extraArgs: {
          customQueryArray: [
            {
              operator: "=",
              column: "record_id",
              value: jobId,
            }
          ],
        },
      })
      .then((response) => {
          if(response?.data?.data[0]?.longText10) {
            setActualData(response?.data?.data[0]?.longText10)
            const fieldContent = response?.data?.data[0]?.longText10.replace(/(<([^>]+)>)/gi, "");
            const splitArray = fieldContent.split("%%%");
            setJobFields(splitArray);
            
          }
      })
      .catch((error) => {
        console.log("error", error);
      });
    }, [navigate]);

    
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const newErrors = {};
        switch (name) {
        case "name":
            if (value.length < 2) newErrors.name = "Full Legal Name is too short!";
            break;
        case "email":
            if (!regExp.test(value)) newErrors.email = "Email is not valid!";
            break;
        case "phone":
            if (value.length < 10) newErrors.phone = "Phone no. is too short!";
            break;
        case "highestLevel":
          if (!value) newErrors.highestLevel = "Field can not be blank!";
          break;
        case "address":
                if (value.length < 10) newErrors.address = "address is too short!";
                break;
        default:
            break;
        }
        if(name && name.indexOf('cutomeField_') >= 0){
            var customSplitName = name.split('_');
            var getIndex = customSplitName[1];
            if(!value){
              newErrors['cutomeField_'+getIndex] = "Field can not be blank!";
            }
            setCustomAns((values) => ({ ...values, [getIndex]: value }));
        } else {
          setFormInputs((values) => ({ ...values, [name]: value }));
        }
        setFormErrors(newErrors);
    };

    const formValid = () => {
        const { name, email, phone, address, commuteBe, lived, isAuthorized, backgroundCheck, interested, otherRole, aboutYou, highestLevel } = formInputs;
        
        const newErrors = {};
        // Full Legal Name errors
        if (!name || name === "")
          newErrors.name = "Full Legal Name can not be blank!";
        else if (name.length < 2)
          newErrors.name = "Full Legal Name is too short!";
        
        // email errors
        if (!email || email === "") newErrors.email = "Email can not be blank!";
        else if (email !== "" && !regExp.test(email))
          newErrors.email = "Email is not valid!";
        
        // phone errors
        if (!phone || phone === "") newErrors.phone = "Phone can not be blank!";
        else if ((phone !== undefined || phone !== "") && phone.length < 10)
          newErrors.phone = "Phone is too short!";
        
        if (!highestLevel || highestLevel === "")
          newErrors.highestLevel = "Field can not be blank!";

        if (!address || address === "")
          newErrors.address = "Address can not be blank!";

        if(jobFields.length > 0) {
          for(var i = 0; i < jobFields.length; i++) {
             if(customAns[i] == undefined || (customAns[i] == '') || (customAns[i] == null)){
                var fName = 'cutomeField_'+i;
                newErrors[fName] = "Field can not be blank!";
             }
          }
        }
        return newErrors;
      };
  
  const handleSubmitApplication = (event) => {
    if(buttonLoading) {
        return false;
    }
    event.preventDefault();
    setFormErrorMessage('');
    const newErrors = formValid();
    setFormErrors(newErrors);
    if (!(Object.keys(newErrors).length > 0)) {
      if(fileError != '') {
        return false;
      }
      const captchaValue = recaptcha.current.getValue()
      if(!captchaValue){
        setFormErrorMessage('Invalid captcha, please try again.');
        return false;
      }
      setButtonLoading(true);
      const actualContent = actualData;
      const splitContent = actualContent.split("%%%");
      var finalString = '';
      splitContent.map((item,index) => {
          if(item != undefined && item != null){
              finalString = finalString + item;
          }
          if(customAns[index] != undefined && customAns[index] != null) {
              finalString = finalString + '<p>Ans: '+customAns[index]+'</p>%%%';
          }
      })
      setFormErrorMessage('');
      const saveData = {
        text_1: formInputs.name,
        text_8: formInputs.email,
        text_20: formInputs.phone,
        text_6: jobId,
	      text_21: formInputs.address,
        text_22: formInputs.highestLevel,
        long_text_15: (finalString) ? finalString : ''

      };
      axios
        .post("record/save-record", {
          saveData: saveData,
          tableId: process.env.REACT_APP_CAN_TABLE_ID,
        })
        .then((response) => {
          recaptcha.current.reset();
          if(response.status == 200 && response?.data?.status == 'success') {
                var recordIdentifier = response.data.data.tableAlias+'-'+response.data.data.recordId;
                setCreatedRecordID(recordIdentifier);
                setFormInputs({});
                
                if(files == '') {
                  setButtonLoading(false);
                  navigate("/company/job-openings/success",{state:{'jobId':jobId,'jobTitle':jobTitle,'fileError':''}});
                } else {
                  handleFileSubmit(event,recordIdentifier);
                }
              } else {
                setButtonLoading(false)
                setFormErrorMessage(response?.data?.message);
            }
        })
        .catch((error) => {
          setButtonLoading(false);
          recaptcha.current.reset();
          if(error.response?.data?.hasOwnProperty('validationMessages')) {
            setFormErrorMessage(error.response?.data?.validationMessages);
          } else {
            setFormErrorMessage(error.response?.data?.message);
          }
          
        });
    }
  };

  function handleFileUpload(event) {
    const filesArray = event.target.files;
    const maxSizeInMb = 100;
    if(filesArray.length > 0) {
        var msg = '';
        var selectedFilesName = '';
        var totalSize = 0;
        const fileFormate = ['doc','docx','pdf','rtf','odt'];
        for(var i = 0; i < filesArray.length; i++) {
          const file = filesArray[i];
          if(file) {
              const fileName = file.name;
              const fileSplit = fileName.split('.');
              const fileType = fileSplit.slice(-1);

              if(!fileType[0] || (fileType[0] == undefined) || !fileFormate.includes(fileType[0])){
                if(msg != '') {
                  msg = msg + ' , ' + fileName;
                } else {
                  msg = fileName;
                }
              }
              totalSize = parseFloat(totalSize) + parseFloat(file.size)/1000;
              selectedFilesName = (selectedFilesName == '') ? fileName : selectedFilesName+' , '+fileName;
          }
        }
        if(msg != '') {
          setFilesName('');
          setFileError(msg+' file format is not allowed. Please upload file in either '+fileFormate + ' format.');
        } else {
          if((parseFloat(totalSize)/1000) > maxSizeInMb) {
            setFilesName('');
            setFileError('Uploading files size too large, Please upload files in '+maxSizeInMb+'MB');
          } else {
            setFiles(filesArray)
            setFilesName(selectedFilesName);
            setFileError('');
          }
        }
    }
  }

  async function handleFileSubmit(event,recordIdentifier) {
    event.preventDefault()
    if(files.length > 0) {
        var fileLastEror = '';
        for(var i = 0; i < files.length; i++) {
          let formData = new FormData();
          var file = files[i];
          formData.append('file', file);
          formData.append('file_name', file.name);
          formData.append('client_id', 1);
          formData.append('record_identifier',recordIdentifier); 
          formData.append('type', 'public');
    
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          await axios.post("v1/submit-attachment-file", formData, config).then((response) => {
            if(response.hasOwnProperty('statusText')) {
              if(!response.data.status) {
                fileLastEror = response.data.message;
              }
            } else {
              if(!response.status) {
                fileLastEror = response.message;
              }
            }
            if(i == parseInt(files.length-1)) {
                setButtonLoading(false);
                navigate("/company/job-openings/success",{state:{'jobId':jobId,'jobTitle':jobTitle,'fileError':fileLastEror}});
            }
          })
        }
    }
  }

  const goToJobList = () => {
    navigate("/company/job-openings/");
  }
  return ( <DocumentMeta {...meta}>
    <>
      <main id="main">
        <section className="about-us-section">
          <div className="container">
            <h2
              className="subheading-text-p aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <span style={{ color: "inherit", wordSpacing: "normal" }}>
                Application: {jobId} - {jobTitle}
                </span>
              </div>
            </h2>

            <div className="row">
                <form className="d-flex mb20" onSubmit={handleSubmitApplication}>
                    <div className="col-md-12">
                      
                        <div className="mb-3 row">
                            <div className="col-sm-12 text-center job-subpara-text">
                                <p>If you are interested in multiple positions, there is no need to submit more than one application. List the other open positions or describe the types of roles that you’d like to be considered for in question #3. </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="mb-3 row">
                                {formErrorMessage &&
                                <p className={"error text-center"}>{formErrorMessage}</p>
                                }
                                    <label className="col-sm-12 col-form-label job-subpara-text">Full Legal Name <span className="error">*</span></label>
                                    <div className="col-sm-12">
                                    <input 
                                    className={`form-control ${
                                        formErrors && formErrors.name ? "error" : ""
                                    }`}
                                    name="name"
                                    value={formInputs.name || ""}
                                    onChange={handleChange}
                                    />
                                    {formErrors && formErrors.name ? 
                                      <label className="job-subpara-text error">{formErrors.name}</label>
                                    :
                                      ''
                                    }
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-12 col-form-label job-subpara-text">Email <span className="error">*</span></label>
                                    <div className="col-sm-12">
                                    <input 
                                    className={`form-control ${
                                        formErrors && formErrors.email ? "error" : ""
                                      }`}
                                    name="email"
                                    value={formInputs.email || ""}
                                    onChange={handleChange}
                                    />
                                    {formErrors && formErrors.name ? 
                                      <label className="job-subpara-text error">{formErrors.email}</label>
                                    :
                                      ''
                                    }
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-12 col-form-label job-subpara-text">Phone Number <span className="error">*</span></label>
                                    <div className="col-sm-12">
                                    <input 
                                    className={`form-control ${
                                        formErrors && formErrors.phone ? "error" : ""
                                      }`}
                                    name="phone"
                                    value={formInputs.phone || ""}
                                    onChange={handleChange}
                                    />
                                    {formErrors && formErrors.phone ? 
                                      <label className="job-subpara-text error">{formErrors.phone}</label>
                                    :
                                      ''
                                    }
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-12 col-form-label job-subpara-text">Highest Level Education, Issuing Institution, and Year of Graduation. <span className="error">*</span></label>
                                    <div className="col-sm-12">
                                    <input 
                                    className={`form-control ${
                                        formErrors && formErrors.highestLevel ? "error" : ""
                                      }`}
                                    name="highestLevel"
                                    value={formInputs.highestLevel || ""}
                                    onChange={handleChange}
                                    />
                                    {formErrors && formErrors.highestLevel ? 
                                      <label className="job-subpara-text error">{formErrors.highestLevel}</label>
                                    :
                                      ''
                                    }
                                    </div>
                                </div>
                                
                                <div className="mb-3 row">
                                    <label className="col-sm-12 col-form-label job-subpara-text">Address <span className="error">*</span></label>
                                    <div className="col-sm-12">
                                    <textarea 
                                    className={`form-control ${
                                        formErrors && formErrors.address ? "error" : ""
                                      }`}
                                    name="address"
                                    value={formInputs.address || ""}
                                    onChange={handleChange}
                                    />
                                    {formErrors && formErrors.address ? 
                                      <label className="job-subpara-text error">{formErrors.address}</label>
                                    :
                                      ''
                                    }
                                    </div>
                                </div>

                                {jobFields && jobFields.length > 0 &&
                                  jobFields.map((item,index) => 
                                    <CustomJobFields 
                                    item={item} 
                                    key={index}
                                    index={index} 
                                    handleChange={handleChange}
                                    customAns={customAns}
                                    formErrors={formErrors}
                                    />
                                  )
                                }

                                <div className="mb-3 row">
                                    <label className="col-sm-12 col-form-label job-subpara-text">Upload Resume (additional application supporting files are welcome).</label>
                                    <label for="inputFiles" id="inputFilesLabel" className="col-sm-12">
                                    <i className="fa fa-upload" style={{paddingRight:10}}></i> Choose Files
                                      <input id="inputFiles" type="file" onChange={handleFileUpload} multiple />
                                    </label>
                                    {fileError ?
                                      <label className="job-subpara-text error">{fileError}</label>
                                      :
                                      <label className="col-form-label job-subpara-text">{filesName}</label>
                                    }
                                </div>
                                <div className="mb-3 row">
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />
                                </div>
                                
                                <div className="mb-3 row">
                                  <div className="col-sm-5">
                                    <button className="common-buttons-medna" onClick={handleSubmitApplication}>
                                      {buttonLoading ? 'Loading...' : 'Submit Application'}
                                    </button>
                                  </div>
                                  <div className="col-sm-4">
                                    <button className="common-back-buttons-medna" onClick={goToJobList}>
                                      Back to Job List
                                    </button>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
              </form>
            </div>
          </div>
        </section>
      </main>
    </>
    </DocumentMeta>
  );
};