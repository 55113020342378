import React, { useState, useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import axios from "../../axios";
import parse from "html-react-parser";
import DocumentMeta from "react-document-meta";
const loading = process.env.REACT_APP_IMAGE_PATH + "loading.gif";

export const JobDetail = () => {
  const [jobData, setJobData] = useState('');
  const [notFound, setNotFound] = useState(false);
  const {jobId} = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    async function getData() {
      try {
        await fetch(process.env.REACT_APP_URL + "static/data/job.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {
            const jsonData = myJson.data;
            if(jsonData.length > 0) { 
              const jobSplit = jobId.split('-');
              const currentJob = jsonData.filter((item) => {
                if(item.recordId == jobSplit[1]){
                  return item;
                }
              })
              if(currentJob[0]) {
                setJobData(currentJob[0]);
              } else {
                  throw new Error("Data not found!")
              }
            } else {
              throw new Error("Data not found!")
            }
          });
      } catch (error) {
        fetchJobDetails();
      }
    }
    getData().then(() => {});
  }, [navigate]);
  const [meta, setMeta] = useState({
    title: "MEDNA Job Details",
    description: "MEDNA Job Details",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "MEDNA Job Details",
      },
    },
  });
  const fetchJobDetails = async () => {
    axios
      .post("v1/records-with-count", {
        tableId: process.env.REACT_APP_JO_TABLE_ID,
        extraArgs: {
          customQueryArray: [{
            operator: "Ø",
            column: "date_6",
            value: "",
          },
          {
            join: "AND",
          },
          {
            operator: "Ø",
            column: "date_4",
            value: "",
          },
          {
            join: "AND",
          },
          {
            operator: "Ø",
            column: "date_7",
            value: "",
          },
          {
            join: "AND",
          },
          {
            operator: "!Ø",
            column: "date_5",
            value: "",
          },
          {
            join: "AND",
          },
          {
            operator: "!Ø",
            column: "date_3",
            value: "",
          },
          {
            join: "AND",
          },
            {
              operator: "=",
              column: "record_id",
              value: jobId,
            }
          ],
        },
      })
      .then((response) => {
        if(response.data.data[0]) {
          setJobData(response.data.data[0]);
        } else {
          navigate("/not-found");
        }
      })
      .catch((error) => {
        navigate("/not-found");
        console.log("error", error);
      });
  }

  const handleApplyNow = (event) => {
    event.preventDefault();
    navigate("/company/job-openings/"+jobId+"/apply",{state:{'jobTitle':jobData.text1}});
  };

  const goToJobList = () => {
    navigate("/company/job-openings/");
  }
  
  return ( <DocumentMeta {...meta}>
    <>
      <main id="main">
        <section className="about-us-section">
          <div className="container">
            <h2
              className="subheading-text-p aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <span style={{ color: "inherit", wordSpacing: "normal" }}>
                Job Details
                </span>
              </div>
            </h2>
            {(jobData) ?
            <div className="row job-details-view">
                <div >
                    <div className="col-sm-12 text-center job-subpara-text">
                        <p style={{ "font-size": "1.5rem", "font-weight": "500"}} >{jobId} - {jobData.text1} </p>
                    </div>
                </div>
                <form className="mb20" onSubmit={handleApplyNow}>
                    <div className="mb20 col-md-12">
                        
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label job-subpara-title">Description</label>
                            <div className="col-sm-10">
                            <span className="job-subpara-text" >{jobData.longText8 ? parse(jobData.longText8) : ''}</span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label job-subpara-title">Pay Range</label>
                            <div className="col-sm-10">
                            <span className="job-subpara-text" >{jobData.text4}</span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-4">
                          </div>
                          <div className="col-sm-2">
                            <button className="common-buttons-medna" onClick={handleApplyNow}>
                              Apply Now
                            </button>
                          </div>
                          <div className="col-sm-2">
                            <button className="common-back-buttons-medna" onClick={goToJobList}>
                              Back to Job List
                            </button>
                          </div>
                        </div>
                    </div>
              </form>
            </div>
            :
            <div style={{textAlign:'center'}}><img style={{maxWidth:'100%'}} src={loading} alt='Loading...' /></div>
            }
          </div>
        </section>
      </main>
    </>
    </DocumentMeta>
  );
};