import React, { useState, useEffect} from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "../../axios";
import CustomPagination from '../../components/custom-pagination';
import DocumentMeta from "react-document-meta";
const loading = process.env.REACT_APP_IMAGE_PATH + "loading.gif";

let itemsPerPage = 10;
export const JobOpening = () => {
  const [searchJobText, setSearchJobText] = useState("");
  const [searchJobBy, setSearchJobBy] = useState("position");
  const [jobData, setJobData] = useState([]);
  const [data, setData] = useState('');
  const [itemOffset, setItemOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [axiosFlag, setAxiosFlag] = useState(false);
  const [initialFetched, setInitialFetched] = useState(false);
  const [tempTotalRecords, setTempTotalRecords] = useState(0);
  const [notFound, setNotFound] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      try {
        await fetch(process.env.REACT_APP_URL + "static/data/job.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {
            setData(myJson?.data);
            setTempTotalRecords(myJson.totalRecords);
            setTotalRecords(myJson.totalRecords);
            setJobDataByJson(0,10,myJson?.data);
            setInitialFetched(true);
          });
      } catch (error) {
        setAxiosFlag(true);
        fetchJobData();
      }
    }

    if(!initialFetched) {
      getData().then(() => {});
    }
  }, [navigate,itemOffset]);
  const [meta, setMeta] = useState({
    title: "MEDNA Job Openings",
    description: "Medna Job Openings",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "medna Job Openings",
      },
    },
  });

  const setJobDataByJson = async (startLimit,endLimit,jsonData='') => {
    var listData = (jsonData) ? jsonData : data;
    if((searchJobText != '') && searchJobText.length > 0) {
        listData = data.filter(item => {
            var fieldDataField = 'text1';
            if (searchJobBy === 'position') {
              fieldDataField = 'text1';
            } else if (searchJobBy === 'group') {
              fieldDataField = 'text12';
            } else if (searchJobBy === 'employee_class') {
              fieldDataField = 'text3';
            } else if (searchJobBy === 'min_degree_requirement') {
              fieldDataField = 'text7';
            }
            if(item[fieldDataField] != null) {
              let searchField = item[fieldDataField].toLowerCase();
              if(searchField.includes(searchJobText.toLowerCase())) {
                return item;
              }
            }
        })
        setTotalRecords(listData.length)
        if(listData.length == 0){
          setNotFound('Jobs not found!');
        }
    } else {
        if(tempTotalRecords > 0){
          setTotalRecords(tempTotalRecords)
        }
    }

    const limitedData = listData.slice([startLimit], [endLimit]);
    setJobData(limitedData);
  }
  const fetchJobData = async () => {
    let customQueryArr = [
      {
        operator: "Ø",
        column: "date_6",
        value: "",
      },
      {
        join: "AND",
      },
      {
        operator: "Ø",
        column: "date_4",
        value: "",
      },
      {
        join: "AND",
      },
      {
        operator: "Ø",
        column: "date_7",
        value: "",
      },
      {
        join: "AND",
      },
      {
        operator: "!Ø",
        column: "date_5",
        value: "",
      },
      {
        join: "AND",
      },
      {
        operator: "!Ø",
        column: "date_3",
        value: "",
      }
    ];
    if(searchJobText.length > 0){
      let fieldDataField = null;
      if (searchJobBy === 'position') {
        fieldDataField = 'text_1';
      } else if (searchJobBy === 'group') {
        fieldDataField = 'text_12';
      } else if (searchJobBy === 'employee_class') {
        fieldDataField = 'text_3';
      } else if (searchJobBy === 'min_degree_requirement') {
        fieldDataField = 'text_7';
      }
      if (fieldDataField !== null) {
        customQueryArr.push({
          join: "AND",
        },
        {
          operator: "like",
          column: fieldDataField,
          value: `${searchJobText || ""}`
        })
      }
    }
    
    axios
      .post("v1/records-with-count?limit="+itemsPerPage+"&offset="+itemOffset, {
        tableId: process.env.REACT_APP_JO_TABLE_ID,
        extraArgs: {
          customQueryArray: customQueryArr,
        },
      })
      .then((response) => {
          if(response.data.totalRecords == 0){
            setNotFound('Jobs not found!');
          }
          setTotalRecords(response.data.totalRecords);
          setJobData(response.data.data);
          setInitialFetched(true);
      })
      .catch((error) => {
        setNotFound('Jobs not found!');
        console.log("error", error);
      });
  }

  const handleJobChange = (e) => {
    setSearchJobText(e.target.value);
  };

  const handleJobSearchByChange = (e) => {
    setSearchJobBy(e.target.value);
  };

  const handleSearch = (event) => {
    setItemOffset(0);
    setNotFound('');
    setJobData({});
    event.preventDefault();
    if(!axiosFlag) {
      setJobDataByJson(0,10,'');
    } else {
      fetchJobData();
    }
  };

  const handlePageClick = (event) => {
    var currentSelected = event.selected;
    const newOffset = (currentSelected * itemsPerPage) % 100; 
    setItemOffset(newOffset);
    if(!axiosFlag) {
        setJobDataByJson(newOffset,(newOffset+itemsPerPage),'');
    }
  };

  return (<DocumentMeta {...meta}>
    <>
      <main id="main">
        <section className="about-us-section">
          <div className="container">
            <h2
              className="subheading-text-p aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <span style={{ color: "inherit", wordSpacing: "normal" }}>
                Job Openings 
                </span>
              </div>
            </h2>
            <div className="row">
                <form className="jobsearchForm row mb20" onSubmit={handleSearch}>
                <div className="col-4">
                <input
                  className="search-job-input"
                  type="search"
                  placeholder="Search "
                  aria-label="Search"
                  onChange={handleJobChange}
                  value={searchJobText}
                />
                </div>
                <div className="col-4">
                <button className="search-form" onClick={handleSearch}>
                  <i className="fa-solid fa-magnifying-glass search-icon-value"></i>
                </button>
                </div>
              </form>
              <table className="job-table">
                  <thead>
                      <tr>
                        <th width={'7%'}>JO#</th>
                        <th width={'33%'}>Position</th>
                        <th width={'10%'}>Group</th>
                        <th width={'15%'}>Class</th>
                        <th width={'10%'}>Location</th>
                        <th width={'15%'}>Pay Range</th>
                        <th width={'10%'}>Min. Degree Requirement</th>
                      </tr>
                  </thead>
                  <tbody>
                  {jobData.length > 0 &&
                    <>
                    {jobData.map((item,index) => 
                        <tr key={index}>
                          <td><Link to={'/company/job-openings/JO-'+item.recordId}>JO-{item.recordId}</Link></td>
                          <td>{item.text1}</td>
                          <td>{item.text12}</td>
                          <td>{item.text3}</td>
                          <td>{item.text2}</td>
                          <td>{item.text4}</td>
                          <td>{item.text7}</td>
                        </tr>
                      )}
                    </>
                  }
                  {(jobData.length == 0 && notFound != '') &&
                  <tr>
                    <td colSpan={7}>
                      <div className="row text-center">
                        <h4 className="text-danger">{notFound}</h4>
                      </div>
                    </td>
                  </tr>
                  }
                  </tbody>
              </table>
              <div style={{marginTop:20}}>
                {(totalRecords > 0 && jobData.length > 0) &&
                <div className="paginationView">
                <CustomPagination 
                onPageChange={handlePageClick}
                itemOffset={itemOffset}
                totalCount={totalRecords}
                itemsPerPage={itemsPerPage}
                />
                </div>
                }
              </div>
              {(jobData.length == 0 && notFound == '') ? 
                <div style={{textAlign:'center'}}><img style={{maxWidth:'100%'}} src={loading} alt='Loading...' /></div>
              :
              <></>
              }
            </div>
          </div>
        </section>
      </main>
    </>
    </DocumentMeta>
  );
};