import React, { useState, useEffect} from "react";
import ReactPaginate from 'react-paginate';

export default function CustomPagination (props)  {
    const { onPageChange, totalCount, itemsPerPage, itemOffset} = props;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(totalCount / itemsPerPage);
    const showStartRecordCount = itemOffset +1;
    return (
        <>
            <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={itemsPerPage}
            onPageChange={onPageChange}
            containerClassName="pagination"
            activeClassName="active"
            />
            <label className="pagination-label">Showing {showStartRecordCount} to {(endOffset > totalCount) ? totalCount : endOffset} of {totalCount} records</label>
        </>
    )
}