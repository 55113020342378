import React, { useState, useEffect } from "react";
import { useParams,useNavigate,useLocation } from "react-router-dom";
import DocumentMeta from "react-document-meta";

export const JobSuccess = (params) => {
  const [meta, setMeta] = useState({
    title: "Medna Job Application",
    description: "Medna Job Application",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Medna Job Application",
      },
    },
  });
  const location = useLocation();
  const jobId = location.state.jobId;
  const jobTitle = location.state.jobTitle;
  const fileError = (location.state.fileError) ? location.state.fileError : '';
  const navigate = useNavigate();
  if(!jobId || (jobId == undefined)){ 
    navigate("/company/job-openings");
  }
  return ( <DocumentMeta {...meta}>
    <>
      <main id="main">
        <section className="about-us-section">
          <div className="container">
            <h2
              className="subheading-text-p aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <span style={{ color: "inherit", wordSpacing: "normal" }}>
                Success!
                </span>
              </div>
            </h2>
            <div className="row">
                <div className="col-md-12">
                    <p className="success-text">Your application for {jobId} {jobTitle} has been submitted. Our hiring team will review your application and get back to you soon. Thank you for your interest in a role at MEDNA Scientific.</p> 
                    <br />
                    {(fileError && fileError.length > 0) && 
                      <p className="success-text error">{'Something went wrong while resume uploading, please direct send resume at info@medna.us'}</p>
                    }
                </div>
            </div>
          </div>
        </section>
      </main>
    </>
    </DocumentMeta>
  );
};
