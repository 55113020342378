import "./App.css";
import Header from "./layouts/header";
import { Footer } from "./layouts/footer";
import { Route, Routes } from "react-router-dom";
import { About } from "./pages/about";
import { Contact } from "./pages/contact";
import { JobOpening } from "./pages/company/job-opening";
import { JobDetail } from "./pages/company/job-detail";
import { JobApplication } from "./pages/company/job-application";
import { JobSuccess } from "./pages/company/success";

// Template Main CSS File -->
import "./assets/css/style.css";
import "./assets/css/media.css";
import NotFoundPage from "./pages/not-found-page";
import { Search } from "./pages/search";

function App() {
  return (
    <>
      <Header></Header>
      <div className="forMenu">
        <Routes>
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/search" element={<Search />} />
          <Route path="*" element={<About />} />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="/company/job-openings" element={<JobOpening />} />
          <Route path="/company/job-openings/:jobId" element={<JobDetail />}  />
          <Route path="/company/job-openings/:jobId/apply" element={<JobApplication />}  /> 
          <Route path="/company/job-openings/success" element={<JobSuccess />}  />        
        </Routes>
      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
